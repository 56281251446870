import { create } from 'zustand';

import { IShipment, TContractState } from '../models/shipment';

export type TShipmentsSortBy = 'name' | 'createdAt' | 'updatedAt';

interface IShipmentsStore {
  rowData: IShipment[];
  pageSize: number;
  offset: number;
  getAll: boolean;
  sortBy: TShipmentsSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TShipmentsSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: TShipmentsReducerInput) => void;
  total: number;
  contractState: TContractState | TContractState[] | null;
  rts: boolean | null;
  isArchived: boolean;
  partnerAutocomplete?: [{ id: string; name: string }] | null;
  searchTerms: {
    boxId: string;
    id: string;
    partnerId: string;
    recipientLastName: string;
    recipientEmail: string;
    senderLastName: string;
    trackingCode: string;
    activeFrom: number | undefined;
    activeTo: number | undefined;
    activeState: string;
  };
  forceRefresh: number;
}

type TShipmentsReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TShipmentsSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateFilters';
      args: {
        contractState: TContractState[];
        deliveryStatus: boolean;
      };
    }
  | {
      type: 'refreshTable';
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  //TODO Rework this to be more specific
  | {
      type: 'updatePartner';
      args: { partnerAutocomplete: [{ id: string; name: string }] | null };
    }
  | {
      type: 'updateSearchTerms';
      args: {
        boxId: string;
        id: string;
        partnerId: string;
        recipientLastName: string;
        recipientEmail: string;
        senderLastName: string;
        trackingCode: string;
        activeFrom: number | undefined;
        activeTo: number | undefined;
        activeState: string;
      };
    }
  | {
      type: 'reset';
    };

const lpShipmentsReducer = (
  state: IShipmentsStore,
  input: TShipmentsReducerInput
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'refreshTable':
      return {
        ...state,
        forceRefresh: (state.forceRefresh || 0) + 1,
      };
    case 'updateFilters':
      if (input.args.contractState === null) {
        return {
          ...state,
          contractState: input.args.contractState,
          rts: input.args.deliveryStatus,
          getAll: true,
        };
      } else if (input.args.contractState.length > 1) {
        return {
          ...state,
          contractState: input.args.contractState as TContractState[],
          isArchived: false,
          ...(input.args.deliveryStatus
            ? { rts: input.args.deliveryStatus }
            : {}),
          getAll: false,
        };
      }
      switch (input.args.contractState[0]) {
        case 'ARCHIVED': {
          return {
            ...state,
            contractState: 'ARRIVED' as TContractState,
            isArchived: true,
            ...(input.args.deliveryStatus
              ? { rts: input.args.deliveryStatus }
              : {}),
            getAll: false,
          };
        }
        case 'ARRIVED': {
          return {
            ...state,
            contractState: 'ARRIVED' as TContractState,
            isArchived: false,
            ...(input.args.deliveryStatus
              ? { rts: input.args.deliveryStatus }
              : {}),
            getAll: false,
          };
        }
        default: {
          return {
            ...state,
            isAchived: false,
            getAll: false,
            contractState: input.args.contractState,
            ...(input.args.deliveryStatus
              ? { rts: input.args.deliveryStatus }
              : {}),
          };
        }
      }
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateSearchTerms':
      return {
        ...state,
        searchTerms: input.args,
        offset: 0,
        contractState: null,
      };
    case 'updatePartner':
      return { ...state, partnerAutocomplete: input.args.partnerAutocomplete };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<IShipmentsStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 25,
  offset: 0,
  getAll: true,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  contractState: null,
  rts: null,
  isArchived: false,
  partnerAutocomplete: null,
  searchTerms: {
    boxId: '',
    id: '',
    partnerId: '',
    recipientLastName: '',
    recipientEmail: '',
    senderLastName: '',
    trackingCode: '',
    activeFrom: undefined,
    activeTo: undefined,
    activeState: '',
  },
  forceRefresh: 0,
};

const useShipmentsStore = create<IShipmentsStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpShipmentsReducer(state, input)),
}));

export const searchTermsSelector = (state: IShipmentsStore) =>
  state.searchTerms;
export const dispatchSelector = (state: IShipmentsStore) => state.dispatch;

export default useShipmentsStore;
