import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';

import { Stack, Text } from '@lp/ds-next';
import forEach from 'lodash/forEach';
import styled from 'styled-components';

import CountrySelector from './forms/CountrySelector';
import SearchField from './forms/SearchField';
import PartnerAutocomplete from './ParnterAutocomplete';
import SearchButton from './SearchButton';
import SearchResetButton from './SearchResetButton';
import useShipmentsStore from '@/features/shipments/hooks/useShipmentsStore';

interface IField {
  label?: string;
  placeholder?: string;
  key: string;
}

interface IServerSearch {
  fields: IField[];
  dispatch: any;
  storeSearchTerms: {
    [key: string]: any;
  };
  label?: string;
  setSelectedRowKeys?;
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
`;

const trimSearchTerms = (searchTerms) => {
  const result = {};
  forEach(searchTerms, (value: string, key: string) => {
    result[key] = !Array.isArray(value) ? value.trim() : value;
  });

  return result;
};

interface URLSearchParams {
  [key: string]: any;
  partners?: string;
}

const getURLSearchParams = (): URLSearchParams => {
  if (typeof window === 'undefined') return {};

  const searchParams = new URLSearchParams(window.location.search);
  const params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

// Utils function to get the URL search params without reloading
const updateURLParams = (params: Record<string, string>) => {
  if (typeof window === 'undefined') return;

  const searchParams = new URLSearchParams();

  // add not empty params
  Object.entries(params).forEach(([key, value]) => {
    if (value && value !== '') {
      searchParams.set(key, value);
    }
  });

  //update the URL
  const newSearch = searchParams.toString();
  const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;

  // use replaceState to avoid adding a new entry in the history
  window.history.replaceState({}, '', newUrl);
};

const ServerSearch = ({
  fields,
  dispatch,
  storeSearchTerms,
  label = 'Search by:',
  setSelectedRowKeys = undefined,
}: IServerSearch) => {
  // get the initial search terms from the URL
  const [initialParams] = useState(getURLSearchParams);
  const partnerAutocomplete = useShipmentsStore(
    (state) => state.partnerAutocomplete
  );

  // local state to manage the search terms
  const [searchTerms, setSearchTerms] = useState(() => {
    // init the terms with the store values
    const terms = {};

    fields.forEach((field) => {
      if (field.key !== 'partnerIds' && field.key !== 'partnerId') {
        if (initialParams[field.key]) {
          terms[field.key] = initialParams[field.key];
        } else if (storeSearchTerms[field.key]) {
          terms[field.key] = storeSearchTerms[field.key];
        } else {
          if (storeSearchTerms['boxId']) {
            terms['id'] = storeSearchTerms['boxId'];
          }
          terms[field.key] = '';
        }
      }
    });

    return terms;
  });

  //State to manage the selected partners
  const [partnerIdsSelected, setPartnerIdsSelected] = useState<any>(() => {
    try {
      if (initialParams.partners) {
        return JSON.parse(initialParams.partners); // Expecting [{ label, value }, ...]
      }
    } catch (e) {
      console.warn('Error while reading partners from URL', e);
    }

    return Array.isArray(partnerAutocomplete)
      ? partnerAutocomplete.map((partner: { name: string; id: string }) => ({
          label: partner.name,
          value: partner.id,
        }))
      : [];
  });

  // Ref to follow the state of the popstate event
  const isHandlingPopstate = useRef(false);

  // check if the partnerIds field exists
  const partnerIDsFieldExists = useMemo(
    () => fields.some((field) => field.key === 'partnerIds'),
    [fields]
  );

  const partnerIDFieldExists = useMemo(
    () => fields.some((field) => field.key === 'partnerId'),
    [fields]
  );

  // function to prepare the search criteria
  const prepareSearchCriteria = useCallback(
    (terms: Record<string, any>, partners: any[]) => ({
      ...terms,
      ...(partnerIDsFieldExists && {
        partnerIds: partners.map((p) => p.value),
      }), // Extract only IDs
      ...(partnerIDFieldExists && { partnerId: partners.map((p) => p.value) }),
    }),
    [partnerIDsFieldExists, partnerIDFieldExists]
  );

  useEffect(() => {
    // Si des paramètres initiaux sont trouvés dans l'URL, mettre à jour le store
    if (Object.keys(initialParams).length > 0) {
      const mergeCriterias = prepareSearchCriteria(
        searchTerms,
        partnerIdsSelected
      );
      dispatch({ type: 'updateSearchTerms', args: mergeCriterias });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParams, prepareSearchCriteria, dispatch]);

  //listen to URL changes (navigation with back/forward button)
  useEffect(() => {
    const handlePopState = () => {
      isHandlingPopstate.current = true;
      const newParams = getURLSearchParams();
      const newTerms = {};
      fields.forEach((field) => {
        if (field.key !== 'partnerIds' && field.key !== 'partnerId') {
          newTerms[field.key] = newParams[field.key] || '';
        }
      });

      let partners = [];
      try {
        if (newParams.partners) {
          partners = JSON.parse(newParams.partners);
        }
      } catch (e) {
        console.error('Error while reading partners from URL', e);
      }

      setSearchTerms(newTerms);
      setPartnerIdsSelected(partners);

      const mergeCriterias = prepareSearchCriteria(newTerms, partners);
      dispatch({ type: 'updateSearchTerms', args: mergeCriterias });
      isHandlingPopstate.current = false;
    };

    // Add the popstate event listener
    window.addEventListener('popstate', handlePopState);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [fields, dispatch, prepareSearchCriteria]);

  // Manage the change of search terms
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setSearchTerms((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  // manage the change of country code
  const handleCountryCodeChange = useCallback((option: string) => {
    setSearchTerms((prev) => ({
      ...prev,
      countryCode: option,
    }));
  }, []);

  // Manage the form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    if (isHandlingPopstate.current) {
      return; // Do nothing if we are handling a popstate event
    }

    const trimmedTerms = trimSearchTerms(searchTerms);

    // Prepare the URL params
    const urlParams = {
      ...trimmedTerms,
      ...(partnerIdsSelected.length > 0 && {
        partners: JSON.stringify(partnerIdsSelected),
      }),
    };

    updateURLParams(urlParams);

    const mergeCriterias = prepareSearchCriteria(
      trimmedTerms,
      partnerIdsSelected
    );

    dispatch({ type: 'updateSearchTerms', args: mergeCriterias });
    if (setSelectedRowKeys) {
      setSelectedRowKeys([]);
    }
  };

  const handleReset = useCallback(() => {
    if (isHandlingPopstate.current) {
      return;
    }

    setSearchTerms(
      fields.reduce((acc, field) => {
        if (field.key !== 'partnerIds' && field.key !== 'partnerId') {
          acc[field.key] = '';
        }

        return acc;
      }, {})
    );

    setPartnerIdsSelected([]);

    updateURLParams({});

    if (setSelectedRowKeys) {
      setSelectedRowKeys([]);
    }

    const clearedState = fields.reduce((a, b) => {
      a[b.key] = '';

      return a;
    }, {});

    dispatch({ type: 'updateSearchTerms', args: clearedState });
  }, [fields, setSelectedRowKeys, dispatch]);

  return (
    <Stack component="form" onSubmit={handleSubmit} rowGap="0.75rem">
      <Text variant="titleM">{label}</Text>
      <StyledRow>
        {fields.map((field: IField) => {
          if (field.key === 'countryCode') {
            return (
              <SearchWrapper key={field.key}>
                <CountrySelector
                  name="country"
                  onChange={handleCountryCodeChange}
                  countryCodeValue={searchTerms[field.key]}
                  placeholder="Country"
                  width="100%"
                />
              </SearchWrapper>
            );
          }
          if (field.key === 'partnerIds' || field.key === 'partnerId') {
            return (
              <SearchWrapper key={field.key}>
                <PartnerAutocomplete
                  placeholder={field.placeholder}
                  style={{ width: '250px', marginRight: '15px' }}
                  updateSearch={setPartnerIdsSelected}
                  isMultiple={field.key === 'partnerIds'}
                  initialValue={partnerIdsSelected}
                  name={field.key}
                />
              </SearchWrapper>
            );
          }

          return (
            <SearchWrapper key={field.key}>
              <SearchField
                key={field.key}
                value={searchTerms[field.key] || ''}
                name={field.key}
                placeholder={field.placeholder}
                label={field.label}
                handleChange={handleChange}
                width="100%"
              />
            </SearchWrapper>
          );
        })}
        <ButtonWrapper>
          <SearchButton />
        </ButtonWrapper>
        <ButtonWrapper>
          <SearchResetButton handleReset={handleReset} />
        </ButtonWrapper>
      </StyledRow>
    </Stack>
  );
};

export default ServerSearch;
